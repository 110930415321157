<template>
  <Pie
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Pie } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "PieChart",
  components: {
    Pie,
  },
  props: {
    chartId: {
      type: String,
      default: "pie-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 253,
    },
    height: {
      type: Number,
      default: 253,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Array,
      default: () => [],
    },
    backgroundColors: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    formatter: {
      type: Function,
      default: (value) => {
        return `${value}`;
      },
    },
  },

  computed: {
    plugins() {
      return [];
    },
    chartData() {
      return {
        labels: this.labels,
        datasets: [
          {
            borderWidth: 0,
            backgroundColor: this.backgroundColors,
            data: this.data,
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "right",
            align: "center",
            labels: {
              useBorderRadius: false,
              usePointStyle: true,
              boxWidth: 10,
              boxHeight: 10,
              color: this.$vuetify.theme.dark ? "#ffffff" : "#333333",
            },
          },
          tooltip: {
            padding: 8,
            boxWidth: 10,
            boxHeight: 10,
            boxPadding: 4,
            usePointStyle: true,
          },
          datalabels: {
            formatter: this.formatter,
            color: "#ffffff",
            font: {
              size: "16px",
              weight: "bold",
            },
          },
        },
      };
    },
  },
};
</script>
