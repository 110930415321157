<template>
  <div>
    <!-- quote -->
    <v-row>
      <v-col cols="12">
        <v-card outlined rounded="xl" class="outlined" :loading="quoteLoading">
          <v-card-text class="d-flex align-center" style="gap: 20px">
            <div class="">
              <v-icon x-large>mdi-thought-bubble</v-icon>
            </div>

            <div v-if="quote">
              <div class="text-body-1 font-weight-bold">
                {{ quote.slip.advice }}
              </div>
              <!-- <div class="text-body-2">
                <v-icon>mdi-minus</v-icon> {{ quote.author }}
              </div> -->
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div
      v-if="showFilters"
      class="mt-6 py-4 d-flex align-center"
      style="gap: 10px"
    >
      <v-menu
        v-model="filter.show"
        :close-on-content-click="false"
        bottom
        offset-y
        nudge-bottom="10"
        origin="top left"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge color="primary" dot overlap :value="isFilterApplied">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <v-card width="340" :outlined="$vuetify.theme.dark">
          <v-card-title>Filters</v-card-title>
          <v-card-text>
            <!-- filter by date_type -->
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              append-icon="mdi-chevron-down"
              v-model="filter.date_filter_type"
              :items="filter.dateTypes"
              item-value="key"
              item-text="value"
              outlined
              dense
              class="rounded-lg"
            >
              <template v-slot:selection="{ item }">
                <span> Filter by {{ item.value }} </span>
              </template>
            </v-select>

            <div v-if="filter.date_filter_type === 'custom'">
              <!-- start date -->
              <v-dialog v-model="filter.startDateDialog" width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formatDate(filter.startDate)"
                    label="Start Date"
                    readonly
                    outlined
                    dense
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="filter.startDate = ''"
                    class="rounded-lg"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filter.startDate"
                  color="primary"
                  @input="filter.startDateDialog = false"
                  :max="maxDate"
                ></v-date-picker>
              </v-dialog>

              <!-- end date -->
              <v-dialog v-model="filter.endDateDialog" width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formatDate(filter.endDate)"
                    label="End Date"
                    readonly
                    outlined
                    dense
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    :disabled="filter.startDate ? false : true"
                    @click:clear="filter.endDate = ''"
                    class="rounded-lg"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filter.endDate"
                  color="primary"
                  @input="filter.endDateDialog = false"
                  :max="maxDate"
                  :min="minDate"
                ></v-date-picker>
              </v-dialog>
            </div>

            <!-- filter by service type -->
            <v-select
              placeholder="Filter by Service Type"
              :menu-props="{ bottom: true, offsetY: true }"
              append-icon="mdi-chevron-down"
              v-model="filter.service_type"
              :items="service_types"
              item-value="key"
              item-text="value"
              return-object
              outlined
              dense
              clearable
              class="rounded-lg"
            >
            </v-select>

            <!-- filter by executive -->
            <v-select
              v-if="
                [$keys.SALES_HEAD, $keys.OPERATIONS_HEAD].includes(
                  user.user_type
                )
              "
              placeholder="Filter by Executive"
              :menu-props="{ bottom: true, offsetY: true }"
              append-icon="mdi-chevron-down"
              v-model="filter.executive"
              :items="executiveList"
              item-value="id"
              item-text="name"
              return-object
              outlined
              dense
              clearable
              class="rounded-lg"
            >
            </v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="px-4" text @click="filter.show = false">
              Cancel
            </v-btn>
            <v-btn class="px-4" color="primary" text @click="applyFilter">
              Apply
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-slide-group>
        <v-slide-x-transition v-if="filters.date_filter_type !== 'custom'">
          <v-slide-item>
            <v-chip class="mx-2 px-4">
              {{ dateFilterType.value }}
            </v-chip>
          </v-slide-item>
        </v-slide-x-transition>

        <v-slide-x-transition
          v-if="
            filters.date_filter_type == 'custom' &&
            (filters.start_date || filters.end_date)
          "
        >
          <v-slide-item>
            <v-chip class="mx-2 px-4">
              <span v-if="filters.start_date">{{
                formatDate(filters.start_date)
              }}</span>
              <span v-if="filters.start_date && filters.end_date"
                >&nbsp;~&nbsp;</span
              >
              <span v-if="filters.end_date">{{
                formatDate(filters.end_date)
              }}</span>
            </v-chip>
          </v-slide-item>
        </v-slide-x-transition>

        <v-slide-x-transition>
          <v-slide-item v-if="filters.service_type">
            <v-chip class="mx-2 px-4">
              {{ filters.service_type.value }}
            </v-chip>
          </v-slide-item>
        </v-slide-x-transition>
        <v-slide-x-transition>
          <v-slide-item v-if="filters.executive">
            <v-chip class="mx-2 px-4">
              {{ filters.executive.name }}
            </v-chip>
          </v-slide-item>
        </v-slide-x-transition>
      </v-slide-group>

      <v-btn icon @click="clearFilter" v-if="isFilterApplied">
        <v-icon>mdi-filter-variant-remove</v-icon>
      </v-btn>
    </div>

    <!-- counts -->
    <v-slide-y-transition>
      <v-row class="mt-3" v-if="topCountData && topCountData.show">
        <v-col
          cols="12"
          md="4"
          v-for="(count, index) in topCountData.counts"
          :key="`top-count-${index}`"
        >
          <v-card
            outlined
            rounded="xl"
            class="outlined"
            :loading="topCountLoading"
          >
            <v-card-text>
              <div class="d-flex align-center justify-space-between">
                <div class="d-flex align-center" style="gap: 10px">
                  <v-icon color="primary"> mdi-{{ count.icon }} </v-icon>
                  <div class="text-body-1 font-weight-medium">
                    {{ count.label }}
                  </div>
                </div>
              </div>
              <div
                class="mt-4 d-flex align-center justify-space-between"
                style="gap: 10px"
              >
                <div class="text-h4 font-weight-bold">{{ count.value }}</div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-slide-y-transition>

    <!-- line-chart -->
    <v-slide-y-transition>
      <v-row v-if="trendsData && trendsData.show">
        <v-col cols="12">
          <v-card
            outlined
            rounded="xl"
            class="outlined"
            :loading="trendsLoading"
          >
            <v-card-text>
              <LineChart
                :labels="trendsData.labels"
                :datasets="trendsData.datasets"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-slide-y-transition>

    <!-- revenue -->
    <v-slide-y-transition>
      <v-row v-if="revenueData && revenueData.show">
        <v-col cols="12">
          <v-card
            outlined
            rounded="xl"
            class="outlined"
            :loading="revenueLoading"
          >
            <v-card-text>
              <div class="d-flex align-center" style="gap: 10px">
                <v-icon color="primary" v-if="revenueData.icon"
                  >mdi-{{ revenueData.icon }}</v-icon
                >
                <div class="text-body-1 font-weight-medium">
                  {{ revenueData.title }}
                </div>
              </div>
              <v-row class="mt-2">
                <v-col
                  cols="12"
                  :md="revenueData.chart_data.length ? 7 : 12"
                  v-if="revenueData.counts.length"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      :md="revenueData.chart_data.length ? 6 : 4"
                      v-for="(count, index) in revenueData.counts"
                      :key="`revenue-count-${index}`"
                    >
                      <v-card outlined rounded="xl">
                        <v-card-text>
                          <div
                            class="d-flex align-center justify-space-between"
                          >
                            <div class="d-flex align-center" style="gap: 10px">
                              <v-icon color="primary" v-if="count.icon"
                                >mdi-{{ count.icon }}</v-icon
                              >
                              <div class="text-body-1 font-weight-medium">
                                {{ count.label }}
                              </div>
                            </div>
                            <v-btn
                              v-if="count.sub_items"
                              class="float-right"
                              icon
                              @click="
                                count.sub_items.show = !count.sub_items.show
                              "
                            >
                              <v-icon v-if="count.sub_items.show"
                                >mdi-chevron-up</v-icon
                              >
                              <v-icon v-else>mdi-chevron-down</v-icon>
                            </v-btn>
                          </div>
                          <div class="mt-4">
                            <div class="text-h4 font-weight-bold">
                              {{ count.value }}
                            </div>
                            <v-slide-y-transition>
                              <div
                                v-if="count.sub_items && count.sub_items.show"
                                class="mt-2"
                              >
                                <div
                                  class="d-flex justify-space-between"
                                  v-for="(item, index) in count.sub_items.items"
                                  :key="`revenue-count-sub-item-${index}`"
                                >
                                  <div class="text-subtitle-2">
                                    {{ item.label }}
                                  </div>
                                  <div class="text-subtitle-2">
                                    {{ item.value }}
                                  </div>
                                </div>
                              </div>
                            </v-slide-y-transition>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="5" v-if="revenueData.chart_data.length">
                  <PieChart
                    :formatter="pieChartFormatter"
                    :labels="getPieChartLabels(revenueData.chart_data)"
                    :data="getPieChartData(revenueData.chart_data)"
                    :backgroundColors="
                      getPieChartBackgroundColors(revenueData.chart_data)
                    "
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-slide-y-transition>

    <!-- revenue -->
    <v-slide-y-transition>
      <v-row v-if="performanceData && performanceData.show">
        <v-col cols="12">
          <v-card
            outlined
            rounded="xl"
            class="outlined"
            :loading="performanceLoading"
          >
            <v-card-text>
              <div class="d-flex align-center" style="gap: 10px">
                <v-icon color="primary" v-if="performanceData.icon"
                  >mdi-{{ performanceData.icon }}</v-icon
                >
                <div class="text-body-1 font-weight-medium">
                  {{ performanceData.title }}
                </div>
              </div>
              <v-row class="mt-2">
                <v-col cols="12" v-if="performanceData.counts.length">
                  <v-row>
                    <v-col
                      cols="4"
                      v-for="(count, index) in performanceData.counts"
                      :key="`performance-count-${index}`"
                    >
                      <v-card outlined rounded="xl">
                        <v-card-text>
                          <div
                            class="d-flex align-center justify-space-between"
                          >
                            <div class="d-flex align-center" style="gap: 10px">
                              <v-icon color="primary" v-if="count.icon">
                                mdi-{{ count.icon }}
                              </v-icon>
                              <div class="text-body-1 font-weight-medium">
                                {{ count.label }}
                              </div>
                            </div>
                          </div>
                          <div
                            class="mt-4 d-flex justify-space-between align-center"
                          >
                            <div class="text-h4 font-weight-bold">
                              {{ count.value }}
                            </div>
                            <v-icon
                              size="80"
                              v-text="'$' + count.rating"
                              v-if="count.rating"
                            >
                            </v-icon>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-slide-y-transition>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

import LineChart from "@/components/Charts/LineChart";
import PieChart from "@/components/Charts/PieChart";

export default {
  name: "Dashboard",
  components: { LineChart, PieChart },

  data() {
    return {
      quote: null,
      quoteLoading: false,

      filter: {
        show: false,
        service_type: "",
        executive: null,
        date_filter_type: "today",
        dateTypes: [
          { key: "today", value: "Today" },
          { key: "yesterday", value: "Yesterday" },
          { key: "this_week", value: "This Week" },
          { key: "this_month", value: "This Month" },
          { key: "this_year", value: "This Year" },
          { key: "custom", value: "Custom Date" },
        ],
        startDate: "",
        startDateDialog: false,
        endDate: "",
        endDateDialog: false,
      },

      executiveList: [],

      topCountLoading: false,
      topCountData: null,

      trendsLoading: false,
      trendsData: null,

      revenueLoading: false,
      revenueData: null,

      performanceLoading: false,
      performanceData: null,
    };
  },

  computed: {
    ...mapGetters({
      user: "getUser",
      service_types: "getServiceTypes",
      filters: "dashboard/filters",
    }),

    showFilters() {
      return (
        (this.topCountData && this.topCountData.show) ||
        (this.trendsData && this.trendsData.show) ||
        (this.revenueData && this.revenueData.show) ||
        (this.performanceData && this.performanceData.show)
      );
    },

    isFilterApplied() {
      const filters = this.filters;
      return (
        filters.service_type ||
        filters.executive ||
        filters.start_date ||
        filters.end_date
      );
    },

    dateFilterType() {
      return this.$_.find(this.filter.dateTypes, {
        key: this.filters.date_filter_type,
      });
    },

    maxDate() {
      const today = this.$moment();
      return today.format("YYYY-MM-DD");
    },

    minDate() {
      const startDate = this.$moment(this.filter.startDate);
      return startDate.add(1, "days").format("YYYY-MM-DD");
    },
  },

  watch: {
    filters() {
      this.getChartData();
    },

    "filter.date_filter_type"() {
      this.filter.startDate = "";
      this.filter.endDate = "";
    },

    "filter.startDate"() {
      this.filter.endDate = "";
    },

    "filter.show"(value) {
      if (value) {
        this.filter.service_type = this.filters.service_type;
        this.filter.executive = this.filters.executive;
        this.filter.date_filter_type = this.filters.date_filter_type;
        this.filter.startDate = this.filters.start_date;
        this.filter.endDate = this.filters.end_date;
      }
    },
  },

  mounted() {
    this.getData();
    this.getExecutiveList();
    this.$root.$on("onNavClicked", this.navClickHandler);
  },

  beforeDestroy() {
    this.$root.$off("onNavClicked", this.navClickHandler);
  },

  methods: {
    ...mapActions({
      updateFilters: "dashboard/updateFilters",
    }),

    formatDate(date) {
      return date ? this.$moment(date).format("DD-MM-YYYY") : "";
    },

    navClickHandler(navKey) {
      if (navKey === "dashboard") {
        this.getData();
      }
    },

    getPieChartLabels(items) {
      const labels = [];
      items.forEach((item) => {
        labels.push(item.label);
      });
      return labels;
    },

    getPieChartData(items) {
      const data = [];
      items.forEach((item) => {
        data.push(item.value);
      });
      return data;
    },

    getPieChartBackgroundColors(items) {
      const colors = [];
      items.forEach((item) => {
        colors.push(item.color);
      });
      return colors;
    },

    getData() {
      this.getQuote();
      this.getChartData();
    },

    getChartData() {
      this.getTopCountData();
      this.getTrendsData();
      this.getRevenueData();
      this.getPerformanceData();
    },

    pieChartFormatter(value) {
      return `₹${value}`;
    },

    async getQuote() {
      this.quoteLoading = true;
      await axios.get("https://api.adviceslip.com/advice").then((res) => {
        this.quote = res.data;
      });
      this.quoteLoading = false;
    },

    applyFilter() {
      this.filter.show = false;
      this.updateFilters({
        service_type: this.filter.service_type,
        executive: this.filter.executive,
        date_filter_type: this.filter.date_filter_type,
        start_date: this.filter.startDate,
        end_date: this.filter.endDate,
      });
    },

    clearFilter() {
      this.updateFilters({
        service_type: "",
        executive: null,
        date_filter_type: "today",
        start_date: "",
        end_date: "",
      });
    },

    getExecutiveList() {
      const onSuccess = (res) => {
        this.executiveList = res.data.data;
      };

      const params = {
        role: this.$keys.SALES_EXECUTIVE,
        apply_pagination: false,
      };

      if (this.user.user_type === this.$keys.OPERATIONS_HEAD) {
        params.role = this.$keys.OPERATIONS_EXECUTIVE;
      }

      return this.$request(this.$keys.GET, this.$urls.team.list, {
        params,
        onSuccess,
      });
    },

    getTopCountData() {
      this.topCountLoading = true;

      const filters = this.filters;
      const params = {
        date_filter_type: filters.date_filter_type,
        executive_id: filters.executive ? filters.executive.id : null,
        service_type: filters.service_type ? filters.service_type.key : "",
        start_date: filters.start_date,
        end_date: filters.end_date,
      };

      const onSuccess = (res) => {
        this.topCountData = res.data.data;
      };

      const onFinally = () => {
        this.topCountLoading = false;
      };

      return this.$request(this.$keys.GET, this.$urls.dashboard.topCounts, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getTrendsData() {
      this.trendsLoading = true;

      const filters = this.filters;
      const params = {
        date_filter_type: filters.date_filter_type,
        executive_id: filters.executive ? filters.executive.id : null,
        service_type: filters.service_type ? filters.service_type.key : "",
        start_date: filters.start_date,
        end_date: filters.end_date,
      };

      const onSuccess = (res) => {
        this.trendsData = res.data.data;
      };

      const onFinally = () => {
        this.trendsLoading = false;
      };

      return this.$request(this.$keys.GET, this.$urls.dashboard.trends, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getRevenueData() {
      this.revenueLoading = true;

      const filters = this.filters;
      const params = {
        date_filter_type: filters.date_filter_type,
        executive_id: filters.executive ? filters.executive.id : null,
        service_type: filters.service_type ? filters.service_type.key : "",
        start_date: filters.start_date,
        end_date: filters.end_date,
      };

      const onSuccess = (res) => {
        this.revenueData = res.data.data;
      };

      const onFinally = () => {
        this.revenueLoading = false;
      };

      return this.$request(this.$keys.GET, this.$urls.dashboard.revenue, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getPerformanceData() {
      this.performanceLoading = true;

      const filters = this.filters;
      const params = {
        date_filter_type: filters.date_filter_type,
        executive_id: filters.executive ? filters.executive.id : null,
        service_type: filters.service_type ? filters.service_type.key : "",
        start_date: filters.start_date,
        end_date: filters.end_date,
      };

      const onSuccess = (res) => {
        this.performanceData = res.data.data;
      };

      const onFinally = () => {
        this.performanceLoading = false;
      };

      return this.$request(this.$keys.GET, this.$urls.dashboard.performance, {
        params,
        onSuccess,
        onFinally,
      });
    },
  },
};
</script>

<style lang="css" scoped>
.outlined {
  border: 1px solid var(--v-primary-base);
}
</style>
