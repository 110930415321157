<template>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Array,
      default: () => [],
    },
    datasets: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    plugins() {
      return [];
    },
    chartData() {
      return {
        labels: this.labels,
        datasets: this.datasets,
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: {
            ticks: {
              color: this.$vuetify.theme.dark ? "#ffffff" : "#333333",
            },
            grid: {
              color: this.$vuetify.theme.dark ? "#424242" : "#EEEEEE",
            },
          },
          yAxes: {
            ticks: {
              color: this.$vuetify.theme.dark ? "#ffffff" : "#333333",
            },
            grid: {
              color: this.$vuetify.theme.dark ? "#424242" : "#EEEEEE",
            },
          },
        },
        plugins: {
          legend: {
            position: "top",
            align: "end",
            labels: {
              useBorderRadius: false,
              usePointStyle: true,
              boxWidth: 10,
              boxHeight: 10,
              color: this.$vuetify.theme.dark ? "#ffffff" : "#333333",
            },
          },
          tooltip: {
            mode: "index",
            intersect: false,
            padding: 8,
            boxWidth: 10,
            boxHeight: 10,
            boxPadding: 4,
            usePointStyle: true,
          },
        },
      };
    },
  },
};
</script>
